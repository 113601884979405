import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  title: {
    textAlign: "center"
  },
  appBar: {
    alignItems: "center"
  }
}));
