import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  intro: {
    textAlign: "center"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "350px",
    padding: theme.spacing(2),
    marginTop: theme.spacing(4)
  },
  linkButton: {
    height: "44px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  paragraph: {
    textAlign: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  response: {
    textAlign: "center"
  }
}));
