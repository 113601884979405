import base64 from "base-64";

export const login = (username, password) =>
  fetch("https://www.toggl.com/api/v8/me", {
    method: "GET",
    headers: {
      Authorization: "Basic " + base64.encode(username + ":" + password)
    }
  });
export const loginAPI = api =>
  fetch("https://www.toggl.com/api/v8/me", {
    method: "GET",
    headers: {
      Authorization: "Basic " + base64.encode(api + ":api_token")
    }
  });
