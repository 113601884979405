import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AppBar, Toolbar, Typography } from "@material-ui/core";

import LoginPanel from "./components/LoginPanel";

// styles
import useStyles from "./styles";

function App(props) {
  const classes = useStyles();

  return (
    <Router>
      <div>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Time Tracker Skill
            </Typography>
          </Toolbar>
        </AppBar>

        <Route path="/">
          <LoginPanel />
        </Route>
      </div>
    </Router>
  );
}

export default App;
