import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Fade,
  TextField,
  Typography,
  Button,
  Box,
  CircularProgress
} from "@material-ui/core";

import { auth } from "../../api";

// styles
import useStyles from "./styles";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function LoginPanel() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loginValue, setLoginValue] = useState("");
  const [returnUrl, setReturnUrl] = useState(null);

  const query = useQuery();
  const [clientId] = useState(query.get("client_id"));
  const [redirectUrl] = useState(query.get("redirect_uri"));
  const [responsetype] = useState(query.get("response_type"));
  const [scope] = useState(query.get("scope"));
  const [state] = useState(query.get("state"));

  function loginUser(token, params) {
    const { redirectUrl, state } = params;

    setIsLoading(true);
    auth
      .loginAPI(token)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setIsLoading(false);
        setSuccess(true);
        setReturnUrl(
          redirectUrl +
            "#state=" +
            state +
            "&access_token=" +
            data.data.api_token +
            "&token_type=Bearer"
        );
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
        setError(true);
      });
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.form}>
        <Typography variant="h4" gutterBottom className={classes.intro}>
          Enter your API key to link your Toggl account to Alexa
        </Typography>

        {!!error && (
          <Fade in={error}>
            <Typography
              color="error"
              display="block"
              className={classes.response}
            >
              It looks like something went wrong. Ckeck your token and try
              again.
            </Typography>
          </Fade>
        )}
        <TextField
          id="email"
          InputProps={{
            classes: {
              underline: classes.textFieldUnderline,
              input: classes.textField
            }
          }}
          value={loginValue}
          onFocus={() => setError(false)}
          onChange={e => setLoginValue(e.target.value)}
          margin="normal"
          placeholder="Toggle API Key"
          type="password"
          fullWidth
        />
        <Button
          disabled={loginValue.length === 0}
          onClick={e => {
            e.preventDefault();
            loginUser(loginValue, {
              clientId: clientId,
              redirectUrl: redirectUrl,
              responsetype: responsetype,
              scope: scope,
              state: state
            });
          }}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          className={classes.linkButton}
        >
          {isLoading ? (
            <CircularProgress size={16} style={{ color: "white" }} />
          ) : (
            "Link Account"
          )}
        </Button>

        {!!success && !!returnUrl && (
          <Fade in={success}>
            <>
              <Typography
                display="block"
                className={classes.response}
                style={{ color: "green" }}
              >
                You've been authenticated by Toggl, let's go back to Alexa!
              </Typography>
              <Button
                color="primary"
                onClick={e => (window.location = returnUrl)}
              >
                Click to finish
              </Button>
            </>
          </Fade>
        )}
        {!success && (
          <Typography className={classes.paragraph}>
            Enter your API token to link your Toggl account to Alexa. You can
            find the API token by signing in your Toggl account in Profile
            Settings on the bottom of the page.
          </Typography>
        )}
        <Typography className={classes.paragraph}>
          Remember that we never store your token in our systems. If you want to
          remove access at any time, you can reset your token in your Profile
          Settings at Toggl.
        </Typography>
      </Box>
    </Box>
  );
}
